body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mobileMenu a {
  color: rgba(255, 255, 255, 0.7);
  padding: 6px 16px;
  font-size: 16px;
  font-weight: 500;
}
.mobileMenu a.active {
  color: #fff;
}
.toggleMenu {
  display: none;
}
.mobile-user {
  display: none;
}
@media screen and (max-width: 1199px) {
  .mobileMenu a,
  header .makeStyles-orgname-2 {
    font-size: 14px;
  }
}
@media screen and (max-width: 1024px) {
  .toggleMenu {
    display: block;
  }
  .mobileMenu,
  header .MuiTypography-h6,
  header .makeStyles-orgname-2 {
    display: none;
  }

  header .activeMenu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    right: 0;
    background-color: white;
  }
  .mobileMenu a {
    color: #000;
  }
  .mobileMenu a.active {
    background-color: #f78b46;
  }
  .desktop-user {
    display: none;
  }
  .mobile-user {
    display: block;
  }

  .mobile-user a {
    margin: 0;
    border: none;
    padding: 7px 16px 10px !important;
    height: auto;
  }
  .mobile-user a:hover {
    background-color: #f78b46;
    color: #fff;
  }

  .mobile-user a div {
    height: 27px;
    width: 27px;
  }
  .mobile-user a div svg {
    top: 0px;
    left: 0px;
    width: 100%;
  }
}
